html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  border-radius: 1em !important;
}

.ant-table-content {
  border-radius: 1em !important;
}
.ant-table-container {
  border-radius: 1em !important;
}
.ant-table > table {
  border-radius: 1em !important;
}
.ant-spin-container {
  border-radius: 1em !important;
}
.ant-table-wrapper {
  border-radius: 1em !important;
}
.ant-table-thead {
  border-radius: 1em !important;
}
.height-100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.avatar-uploader,
.avatar-uploader img,
.avatar-uploader .ant-upload {
  width: 100% !important;
}

.avatar-uploader-cover,
.avatar-uploader-cover img,
.avatar-uploader-cover .ant-upload {
  width: 100% !important;
  height: 250px !important;
}

/* hide time picker scrollbars */
ul.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 0px;
}

.w-100 {
  width: 100%;
}

.modal-ok-btn {
  float: left;
  margin-right: 4px;
}

.modal-no-btn {
  float: right;
  margin-left: 4px;
}

.hidden-action {
  visibility: hidden;
}

.ql-editor,
.ql-editor * {
  color: black;
}

hr {
  border: #dadada solid 1px;
  border-top: 0;
}

.vl {
  border-right: 1px solid #dadada;
}

.ant-steps-item-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.ant-steps-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
